<template>
  <div class="checkblock">
    <div class="checkmark-label narrow-margin">
      <div :class="[checked ? 'active' : 'inactive', 'checkmark']">
        <svg
          v-if="checked"
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
    </div>
    <div class="block">
      <p class="item-name">{{ shipping.title }}</p>
      <div class="flex items-center justify-between">
        <div class=" text-theme-dark text-base md:text-xl font-semibold">
          <template v-if="shipping.subsidy > 0">
            <span v-if="shipping.cost == shipping.subsidy">Free</span>
            <span v-else>
              <del class="text-red-400">{{
                commaFormat(shipping.displayCost)
              }}</del>
              - ${{ commaFormat(shipping.subsidy) }}
            </span>
          </template>
          <span v-else>
            {{ commaFormat(shipping.displayCost) }}
          </span>
        </div>
      </div>
    </div>
    <div
      :class="[
        checked
          ? 'border-theme-indigo border-opacity-50'
          : 'border-transparent',
        'absolute -inset-px rounded-lg border-2 pointer-events-none',
      ]"
      aria-hidden="true"
    />
  </div>
</template>

<script>
export default {
  name: "ShippingOption",
  props: {
    shipping: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    commaFormat: (num) => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkblock {
  @apply h-full relative flex flex-col rounded-lg border border-theme-border bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 focus:outline-none select-none;

  &.disabled {
    @apply bg-theme-bg hover:bg-theme-bg border-theme-border hover:border-theme-border cursor-default pointer-events-none;
  }

  &.min-height {
    min-height: 175px;
  }

  .checkmark-label {
    @apply flex w-full justify-end -mb-6;

    &.narrow-margin {
      @apply -mb-4;
    }

    .checkmark {
      @apply border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center;

      &.active {
        @apply bg-theme-indigo border-theme-indigo text-white;
      }

      &.inactive {
        @apply bg-white border-theme-border;
      }
    }
  }
}
</style>
